import {
  addDays,
  addMonths,
  differenceInDays,
  eachDayOfInterval,
  format,
  isAfter,
  isBefore,
  isSameDay,
  isWithinInterval,
  parseISO,
  subDays,
  subMonths,
} from 'date-fns';

import {
  CalendarMonths,
  DatesRange,
  FuzzyDatesOffset,
} from '@utils/types/calendar';
import { parseDateWithoutTimezone } from '@utils/dates';
import { splitByCommas } from '@utils/helpers';
import { toDollars } from '@utils/money';
import { ListingEstimateDiscount } from '@utils/types/estimate';
import { isEmpty } from 'lodash';
import { maxDayGapBetweenReservations } from '@components/listing/listingCalendar/constants';

const generateMonthInfo = (month: Date) => {
  month.setDate(1);
  const day = month.getDay();
  const daysInMonth = new Date(
    month.getFullYear(),
    month.getMonth() + 1,
    0
  ).getDate();
  return {
    year: month.getFullYear(),
    month: month.getMonth(),
    days: [
      ...Array.from(new Array(day).keys()).map(() => ''),
      ...Array.from(new Array(daysInMonth).keys()).map((d) => d + 1),
    ],
  };
};

const generateMonthsData = (numberOfMonths = 12) =>
  Array.from(new Array(numberOfMonths).keys()).map((month) => {
    const monthDate = addMonths(new Date(), month);
    return generateMonthInfo(monthDate);
  });

const generatePastMonthsData = (numberOfMonths = 12) =>
  Array.from(new Array(numberOfMonths).keys())
    .map((month) => {
      const monthDate = subMonths(new Date(), month + 1);
      return generateMonthInfo(monthDate);
    })
    .reverse();

const getDefaultMonth = (
  selectedDates: DatesRange,
  months: CalendarMonths,
  firstAvailableDate?: string | null
) => {
  if (selectedDates.from) {
    const selectedMonthIndex = months.findIndex(
      (m) =>
        m.month === selectedDates.from?.getMonth() &&
        m.year === selectedDates.from?.getFullYear()
    );
    if (selectedMonthIndex) return months[selectedMonthIndex];
  }

  if (firstAvailableDate) {
    const firstAvailableMonthIndex = months.findIndex(
      (m) =>
        m.month === parseDateWithoutTimezone(firstAvailableDate).getMonth() &&
        m.year === parseDateWithoutTimezone(firstAvailableDate).getFullYear()
    );
    if (firstAvailableMonthIndex) {
      return months[Math.min(firstAvailableMonthIndex, months.length - 2)];
    }
  }

  return months[0];
};

const prepareArrayOfDisabledDates = (
  reservedDates: { start: string; end: string }[],
  minNightsStay?: number
) => {
  const disabledDates = reservedDates
    .map(({ start, end }) => {
      const dates = eachDayOfInterval({
        start: parseISO(start),
        end: parseISO(end),
      });
      return dates.map((date) => date.toISOString());
    })
    .flat();

  /**
   * Fill the gaps between the reserved dates
   * if the gap is less than the minNightsStay
   */
  if (minNightsStay) {
    for (let i = 0; i < disabledDates.length - 1; i++) {
      const daysBetween = differenceInDays(
        new Date(disabledDates[i + 1]),
        new Date(disabledDates[i])
      );
      if (daysBetween > 1 && daysBetween < minNightsStay) {
        const datesBetween = eachDayOfInterval({
          start: new Date(disabledDates[i]),
          end: new Date(disabledDates[i + 1]),
        });
        disabledDates.push(...datesBetween.map((date) => date.toISOString()));
      }
    }
  }

  /**
   * Add today's date to the disabled dates
   * to have an anchor for gap restrictions
   */
  disabledDates.push(new Date().toISOString());

  return disabledDates.sort();
};

const showMonthsAndDays = (start: string, end: string) => {
  const days = differenceInDays(
    parseDateWithoutTimezone(end),
    parseDateWithoutTimezone(start)
  );

  if (days < 30) return `${days} nights`;

  let months = Math.floor(days / 30);
  const remainingDays = differenceInDays(
    subMonths(parseDateWithoutTimezone(end), months),
    parseDateWithoutTimezone(start)
  );
  const monthString = months === 1 ? 'month' : 'months';

  if (remainingDays <= 0) return `${months} ${monthString}`;

  return `${months} ${monthString} ${remainingDays} days`;
};

const formatSuggestionPrice = (price: number) => {
  return splitByCommas(toDollars(price, 0));
};

const calculateSuggestionNightlyDiscount = (
  discounts: ListingEstimateDiscount[],
  days: number
) => {
  const upsellDiscount = discounts.find((d) => d.type.includes('upsell_'));
  return (upsellDiscount?.amount || 0) / days;
};

const isDayCreatingInvalidGap = (
  date: Date,
  disabledDates: string[],
  minNightsStay: number,
  isCheckoutDay: boolean,
  ignorePreviousReservations: boolean
) => {
  /**
   * For non-monthly stays we should only check if the gap between the reservations
   * is less than the minNightsStay.
   * For monthly stays we should also check if the gap is more than the maxDayGapBetweenReservations
   */
  const firstDayOfNextReservation = disabledDates.find(
    (d) => new Date(d) >= date
  );
  const lastDayOfPrevReservation = disabledDates
    .filter((d) => new Date(d) < date)
    .pop();
  const daysBetweenFirstDayOfNextReservationAndDate = firstDayOfNextReservation
    ? differenceInDays(new Date(firstDayOfNextReservation), new Date(date))
    : undefined;
  const daysBetweenLastDayOfPrevReservationAndDate = lastDayOfPrevReservation
    ? differenceInDays(new Date(date), new Date(lastDayOfPrevReservation))
    : undefined;
  if (minNightsStay < 30) {
    /**
     * Account for the fact that check-out can happen in the day of someone's check-in
     */
    if (firstDayOfNextReservation === date.toISOString()) {
      return false;
    }
    return !!(
      daysBetweenFirstDayOfNextReservationAndDate &&
      daysBetweenFirstDayOfNextReservationAndDate < minNightsStay
    );
  }

  if (daysBetweenFirstDayOfNextReservationAndDate) {
    if (daysBetweenFirstDayOfNextReservationAndDate < minNightsStay) {
      return (
        !isCheckoutDay ||
        daysBetweenFirstDayOfNextReservationAndDate >
          maxDayGapBetweenReservations
      );
    }
  }

  if (
    !ignorePreviousReservations &&
    daysBetweenLastDayOfPrevReservationAndDate
  ) {
    if (
      daysBetweenLastDayOfPrevReservationAndDate >
        maxDayGapBetweenReservations &&
      daysBetweenLastDayOfPrevReservationAndDate <= minNightsStay
    ) {
      return true;
    }
  }

  return false;
};

const isDayInInvalidRange = (
  date: Date,
  selectedDates: DatesRange,
  disabledDates: string[]
) => {
  const idxOfDisabledDate = disabledDates.findIndex(
    (d) => d === date.toISOString()
  );

  /**
   * The date should be disabled if it is one of the disabled dates
   * parsed from reserved dates ranges
   * BUT
   * if the .from date is selected then we should let user
   * select a reserved date as a checkout day
   * (as check-out can happen in the day of someone's check-in)
   * AND
   * for the 1-day stays we should disable the next day
   * after a reserved one, to make it visually logical
   * (this is what prevDate functionality does)
   */

  if (!selectedDates.from || (!!selectedDates.from && !!selectedDates.to)) {
    return idxOfDisabledDate !== -1;
  }

  const prevDate = subDays(date, 1);
  const idxOfPrevDateInDisabledDates = disabledDates.findIndex(
    (d) => d === prevDate.toISOString()
  );
  let isPrevDayOneDayStayReservation = false;

  if (idxOfPrevDateInDisabledDates > 0) {
    isPrevDayOneDayStayReservation =
      differenceInDays(
        new Date(disabledDates[idxOfPrevDateInDisabledDates]),
        new Date(disabledDates[idxOfPrevDateInDisabledDates - 1])
      ) !== 1 &&
      (idxOfPrevDateInDisabledDates === disabledDates.length - 1 ||
        differenceInDays(
          new Date(disabledDates[idxOfPrevDateInDisabledDates + 1]),
          new Date(disabledDates[idxOfPrevDateInDisabledDates])
        ) !== 1);
  }

  if (isPrevDayOneDayStayReservation) return true;

  if (idxOfDisabledDate === -1) return false;

  const isFirstDayInReservedRange =
    differenceInDays(
      new Date(disabledDates[idxOfDisabledDate]),
      new Date(disabledDates[idxOfDisabledDate - 1])
    ) !== 1;

  return !isFirstDayInReservedRange;
};

const doesDayHaveRangeBetweenItselfAndSelectedFrom = (
  date: Date,
  selectedDates: DatesRange,
  disabledDates: string[]
) => {
  if (!selectedDates.from || (selectedDates.from && selectedDates.to)) {
    return false;
  }

  if (
    isBefore(date, selectedDates.from) ||
    isSameDay(date, selectedDates.from)
  ) {
    return false;
  }

  const datesBetween = eachDayOfInterval({
    start: selectedDates.from,
    end: subDays(date, 1),
  });

  return datesBetween.some((date) =>
    disabledDates.includes(date.toISOString())
  );
};

const isDayInTheRange = (date: Date, selectedDates: DatesRange) =>
  selectedDates.from && selectedDates.to
    ? isWithinInterval(date, {
        start: selectedDates.from,
        end: selectedDates.to,
      })
    : false;

const isDayFirstInInterval = (date: Date, selectedDates: DatesRange) =>
  selectedDates.from?.toISOString() === date.toISOString();
const isDayLastInInterval = (date: Date, selectedDates: DatesRange) =>
  selectedDates.to?.toISOString() === date.toISOString();

const isDayInFuzzyRange = (
  date: Date,
  selectedDates: DatesRange,
  fuzzyDatesOffset?: FuzzyDatesOffset
) => {
  if (!fuzzyDatesOffset) return false;

  if (selectedDates.from && fuzzyDatesOffset.from) {
    if (isSameDay(date, selectedDates.from)) return false;

    if (
      isWithinInterval(date, {
        start: subDays(selectedDates.from, fuzzyDatesOffset.from),
        end: addDays(selectedDates.from, fuzzyDatesOffset.from),
      })
    ) {
      return true;
    }
  }

  if (selectedDates.to && fuzzyDatesOffset.to) {
    if (isSameDay(date, selectedDates.to)) return false;

    if (
      isWithinInterval(date, {
        start: subDays(selectedDates.to, fuzzyDatesOffset.to),
        end: addDays(selectedDates.to, fuzzyDatesOffset.to),
      })
    ) {
      return true;
    }
  }

  return false;
};

const isDayUnavailableDueToMinNightRules = (
  date: Date,
  selectedDates: DatesRange,
  minNightsStay?: number
) =>
  selectedDates.from && !selectedDates.to && minNightsStay && minNightsStay > 1
    ? isWithinInterval(date, {
        start: addDays(selectedDates.from, 1),
        end: addDays(selectedDates.from, minNightsStay - 1),
      })
    : false;

const isDayUnavailableDueToMaxNightRules = (
  date: Date,
  selectedDates: DatesRange,
  maxNightsStay?: number
) =>
  selectedDates.from && !selectedDates.to && maxNightsStay && maxNightsStay > 1
    ? isAfter(date, addDays(selectedDates.from, maxNightsStay))
    : false;

const isDayAValidStartDate = (
  date: Date,
  selectedDates: DatesRange,
  disabledDates: string[],
  minNightsStay?: number,
  ignorePreviousReservations?: boolean
) => {
  if (!selectedDates?.from || (selectedDates?.from && selectedDates?.to)) {
    return !isDayCreatingInvalidGap(
      date,
      disabledDates,
      minNightsStay || 30,
      false,
      ignorePreviousReservations || false
    );
  }
  return false;
};

const isDayAValidEndDate = (
  date: Date,
  selectedDates: DatesRange,
  disabledDates: string[],
  minNightsStay?: number,
  ignorePreviousReservations?: boolean
) => {
  if (selectedDates?.from && !selectedDates?.to) {
    return !isDayCreatingInvalidGap(
      date,
      disabledDates,
      minNightsStay || 30,
      true,
      ignorePreviousReservations || false
    );
  }
  return false;
};

const isDayPastLastKnownDay = (date: Date, lastKnownDay?: string | null) => {
  return lastKnownDay && isAfter(date, new Date(lastKnownDay));
};

const detectMinNightsStayFromGuestyCalendar = (
  from: DatesRange['from'],
  calendarMinNights: Record<string, Record<string, number[]>>
) => {
  let minNightsStayFromGuestyCalendar: number | null = null;

  if (from && !isEmpty(calendarMinNights)) {
    const calendarYear = calendarMinNights[new Date(from).getFullYear()];
    const calendarMonth = calendarYear
      ? calendarYear[('0' + (new Date(from).getMonth() + 1)).slice(-2)]
      : null;
    if (calendarYear && calendarMonth) {
      minNightsStayFromGuestyCalendar =
        calendarMonth[new Date(from).getDate() - 1];
    }
  }
  return minNightsStayFromGuestyCalendar;
};

const isRangeFromQueryParametersInvalid = (
  dates: DatesRange,
  disabledDates: string[],
  maxAmount: number,
  minAmount: number
) => {
  const { from, to } = dates;

  if (from && to) {
    const totalDays = differenceInDays(to, from);

    /**
     * Min and max amount of days that can be reserved
     */
    if (totalDays > maxAmount || totalDays < minAmount) {
      return true;
    }

    const dates = eachDayOfInterval({ start: from, end: to });

    /**
     * Respecting the fact that check-out can happen in the day of someone's check-in
     * so the last date in the interval can be reserved
     */
    dates.pop();

    /**
     * Check if the range overlaps with disabled dates
     */

    let isInvalid = false;
    for (let date of dates) {
      isInvalid = disabledDates.includes(date.toISOString());
      if (isInvalid) {
        break;
      }
    }
    return isInvalid;
  }

  return false;
};

const getDayTooltipText = ({
  date,
  isUnavailableDueToMinNightRules,
  isUnavailableDueToMaxNightRules,
  isFirstDayInInterval,
  isLastDayInInterval,
  isBeforeSelectedFrom,
  isDisabled,
  isAvailableButNotSelectable,
  isSelected,
  isValidStartDate,
  isValidEndDate,
  minNightsStay,
  maxNightsStay,
  selectNewEndDateMode,
}: {
  date: Date;
  isUnavailableDueToMinNightRules: boolean;
  isUnavailableDueToMaxNightRules: boolean;
  isFirstDayInInterval: boolean;
  isLastDayInInterval: boolean;
  isBeforeSelectedFrom: boolean;
  isDisabled: boolean;
  isAvailableButNotSelectable: boolean;
  isSelected: boolean;
  isValidStartDate: boolean;
  isValidEndDate: boolean;
  minNightsStay?: number;
  maxNightsStay?: number;
  selectNewEndDateMode?: boolean;
}) => {
  if (isFirstDayInInterval) {
    if (selectNewEndDateMode) {
      return 'Old check-in date';
    }
    return 'Check-in date';
  }
  if (isLastDayInInterval) {
    if (selectNewEndDateMode) {
      return 'Old check-out date';
    }
    return 'Check-out date';
  }
  if (isUnavailableDueToMinNightRules) {
    return `Your reservation needs to be a minimum of ${minNightsStay} days`;
  }
  if (isUnavailableDueToMaxNightRules) {
    return `Your reservation needs to be a maximum of ${maxNightsStay} days`;
  }
  if (isBeforeSelectedFrom) {
    if (selectNewEndDateMode) {
      return 'You need to select a new check-out date';
    }
    return 'You need to select a check-out date';
  }
  if (isAvailableButNotSelectable) {
    const minGap = Math.min(maxDayGapBetweenReservations, minNightsStay || 30);

    if (minNightsStay && minNightsStay >= 30) {
      return `You cannot leave more than a ${minGap} day gap or less than a ${minNightsStay} day gap between reservations`;
    }

    return `You cannot leave less than a ${minGap} day gap between reservations`;
  }
  if (isDisabled) {
    return 'Unavailable';
  }
  if (isSelected) {
    return 'Selected';
  }
  if (isValidStartDate) {
    return 'Valid start date';
  }
  if (isValidEndDate) {
    return 'Valid end date';
  }

  return format(date, 'MMMM do');
};

export {
  generatePastMonthsData,
  generateMonthsData,
  prepareArrayOfDisabledDates,
  getDefaultMonth,
  showMonthsAndDays,
  formatSuggestionPrice,
  calculateSuggestionNightlyDiscount,
  detectMinNightsStayFromGuestyCalendar,
  isDayInInvalidRange,
  isDayInTheRange,
  isDayFirstInInterval,
  isDayLastInInterval,
  isDayInFuzzyRange,
  isDayUnavailableDueToMinNightRules,
  isDayUnavailableDueToMaxNightRules,
  isDayAValidStartDate,
  isDayAValidEndDate,
  isDayPastLastKnownDay,
  isRangeFromQueryParametersInvalid,
  doesDayHaveRangeBetweenItselfAndSelectedFrom,
  getDayTooltipText,
};
