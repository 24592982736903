import clsx from 'clsx';
import { format, isPast } from 'date-fns';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';

import { BaseComponentProps } from '@utils/types/baseComponents';
import {
  CalendarMonth as CalendarMonthType,
  DatesRange,
  FuzzyDatesOffset,
} from '@utils/types/calendar';

import styles from './styles.module.scss';
import {
  doesDayHaveRangeBetweenItselfAndSelectedFrom,
  isDayFirstInInterval,
  isDayInFuzzyRange,
  isDayInInvalidRange,
  isDayInTheRange,
  isDayLastInInterval,
} from '@components/listing/listingCalendar/helpers';

type Props = {
  month: CalendarMonthType;
  selectedDates: DatesRange;
  onDateSelect: (date: Date) => void;
  fuzzyDatesOffset?: FuzzyDatesOffset;
  disabledDates?: string[];
} & BaseComponentProps;

export const RangePickerMonth = (props: Props) => {
  const {
    month: { days, month, year },
    selectedDates,
    onDateSelect,
    fuzzyDatesOffset,
    disabledDates = [],
  } = props;

  return (
    <div className={styles.month}>
      <div className={styles.days}>
        {days.map((day, idx) => {
          if (typeof day === 'number') {
            const date = new Date(year, month, day);

            const isSelected = isDayInTheRange(date, selectedDates);

            const isUnavailableDueToInvalidRange = isDayInInvalidRange(
              date,
              selectedDates,
              disabledDates
            );

            const doesHaveRangeBetweenItselfAndSelectedFrom =
              doesDayHaveRangeBetweenItselfAndSelectedFrom(
                date,
                selectedDates,
                disabledDates
              );

            const isDisabled =
              isPast(date) ||
              isUnavailableDueToInvalidRange ||
              doesHaveRangeBetweenItselfAndSelectedFrom;

            const isFirstDayInInterval = isDayFirstInInterval(
              date,
              selectedDates
            );

            const isLastDayInInterval = isDayLastInInterval(
              date,
              selectedDates
            );

            const isInFuzzyRange = isDayInFuzzyRange(
              date,
              selectedDates,
              fuzzyDatesOffset
            );

            return (
              <Tooltip
                key={`${day}-${idx}`}
                placement="top"
                overlay={<span>{format(date, 'MMMM do')}</span>}
                overlayClassName="calendar-listing-tooltip"
                mouseLeaveDelay={0}
              >
                <div
                  onClick={() => !isDisabled && onDateSelect(date)}
                  className={clsx([
                    styles.dayInMonth,
                    day && styles.dayInMonth__withOutline,
                    isDisabled && styles.dayInMonth__disabled,
                    isSelected && styles.dayInMonth__selected,
                    isInFuzzyRange && styles.dayInMonth__fuzzyRange,
                    (isFirstDayInInterval || isLastDayInInterval) &&
                      styles.dayInMonth__firstOrLastInInterval,
                  ])}
                >
                  {date.getDate()}
                </div>
              </Tooltip>
            );
          }
          return <div key={`${day}-${idx}`} />;
        })}
      </div>
    </div>
  );
};
